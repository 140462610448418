#why-choose-us {
  color: #313131;

  .container-choose {
    width: 100%;
  }

  .title {
    text-align: start;
    color: #141414;
    font-size: 48px;
    font-weight: 700;
    line-height: 1.25;
    .title__bg {
      display: inline-block;
      justify-content: center;
      align-items: center;
      color: #fff;
      padding: 3px 12px;
      border-radius: 100px;
      background: linear-gradient(
        90deg,
        #ff8008 0%,
        #fd8c06 33.33%,
        #fb9904 66.67%,
        #f8b300 100%
      );
    }
  }

  .wrapper-cards {
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 45px auto 0px;
    max-width: 1250px;
    padding-left: 75px;

    .card-item {
      z-index: 1;
      max-width: 1030px;
      display: flex;
      align-items: flex-start;
      gap: 70px;
      padding: 40px 20px 40px 40px;
      background: rgb(255, 255, 255, 0.3);
      border: 2px solid rgb(255, 255, 255, 1);
      border-radius: 20px;
      backdrop-filter: blur(32px);
      text-align: start;
      -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 20px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 20px;
      opacity: 0;
      transform: translateY(50px);
      transition: opacity 0.6s, transform 0.6s;

      &.fade-in {
        opacity: 1;
        transform: translateY(0);
      }

      &:nth-child(2) {
        margin-left: 150px;
      }
      &:last-child {
        margin-left: 75px;
      }

      .item-text {
        font-weight: 500;
        font-size: 22px;
        line-height: 1.5;
        word-break: unset;
      }
    }
  }

  @media (max-width: 1200px) {
    .title {
      font-size: 38px;
    }
    .wrapper-cards {
      max-width: 1100px;
      padding-left: 0px;
    }
  }

  @media (max-width: 991px) {
    .wrapper-cards {
      max-width: 720px;
      padding-left: 0px;

      .card-item {
        max-width: 700px;
        gap: 30px;

        &:nth-child(2) {
          margin-left: 0;
        }
        &:last-child {
          margin-left: 0;
        }
      }
    }
  }

  @media (max-width: 780px) {
    .wrapper-cards {
      max-width: 540px;
      padding-left: 0px;
      padding: 0 15px;

      .card-item {
        max-width: 540px;
        gap: 30px;
        padding: 20px;
      }
    }
  }

  @media (max-width: 580px) {
    .title {
      font-size: 32px;
    }
  }
  @media (max-width: 360px) {
    .wrapper-cards {
      .card-item {
        gap: 10px;
        .item-text {
          font-size: 16px;
        }
      }
    }
  }
}